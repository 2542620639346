<template>
    <div class="service-platform-page">
        <div class="top-bg">
            <div class="content">
                <div class="list-item">
                    <img class="item-img" src="../../assets/platform_img/pic_mkd_logo.png" />
                    <div class="item-btn pointer" @click="handleOpen('coalMineUrl')">前往疆煤宝煤矿端</div>
                </div>
                <div class="list-item">
                    <!-- <div class="tips">系统即将上线，敬请期待！</div> -->
                    <img class="item-img" src="../../assets/platform_img/pic_iconbg.png" />
                    <div class="item-btn pointer" @click="handleOpen('erpUrl')">前往疆煤宝贸易端</div>
                </div>
                <div class="list-item">
                    <img class="item-img" :src="driverQrcode" />
                    <div class="item-btn">扫码前往疆煤宝司机端</div>
                </div>
            </div>
        </div>
        <!-- 招商服务 -->
        <div class="partner">
            <div class="partner_on">
                <div class="partner_tw">
                    <div class="partner_title">合作伙伴</div>
                    <div class="partner_title2">
                        新疆煤炭交易中心与合作伙伴一起建设共赢煤炭交易生态圈
                    </div>
                </div>
                <div class="panter_list">
                    <div @click="toPartner(item.jump_url)" class="partner_item" :key="i"
                        v-for="(item, i) in partnerList">
                        <el-image style="width: 180px; height: 72px" :src="item.partner_img" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getpartnerList } from "@/api/home";
const { baseUrl } = require("@/config/index.js");
export default {
    data() {
        return {
            coalMineUrl: process.env.VUE_APP_URL_BY_COAL_MINE,
            erpUrl: process.env.VUE_APP_URL_BY_ERP,
            driverQrcode: process.env.VUE_APP_URL_BY_DRIVER_QRCODE,
            partnerList: []
        }
    },
    created() {
        this.queryPartnerList();
    },
    methods: {
        isJSON(str) {
            if (typeof str == "string") {
                try {
                    JSON.parse(str);
                    return true;
                } catch (e) {
                    return false;
                }
            }
        },
        async queryPartnerList() {
            const that_ = this;
            let a = document.URL;
            var str1 = a.substring(0, a.indexOf("#"));
            let dictLabel = null;
            if (str1 == "https://north.xjcec.com/") {
                dictLabel = 1;
            } else {
                dictLabel = 0;
            }
            const partner = await getpartnerList({
                dictType: "partner",
                pageNum: 1,
                pageSize: 12,
                dictLabel: dictLabel,
            });
            if (partner.code == 0) {
                this.partnerList = [];
                console.log(partner.data)
                partner.data.list.map((item) => {
                    if (that_.isJSON(item.dictValue)) {
                        let obx = JSON.parse(item.dictValue);
                        item.partner_img =
                            baseUrl + "third/third/ossGetFile/" + obx.partner_img;
                        item.jump_url = obx.jump_url;
                        this.partnerList.push(item);
                    }
                });
            }
        },
        toPartner(url) {
            if (url) {
                window.open(url, "_blank");
            }
        },
        handleOpen(url) {
            if (url === 'coalMineUrl') {
                window.open(this.coalMineUrl, "_blank");
            } else {
                window.open(this.erpUrl, "_blank");
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.service-platform-page {
    .top-bg {
        height: 950px;
        background: url('../../assets/platform_img/pic_logn_bj.png') no-repeat top center;

        .content {
            padding-top: 400px;
            display: flex;
            justify-content: center;

            .list-item {
                width: 320px;
                height: 360px;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.89) 0%, rgba(255, 255, 255, 0.62) 100%);
                border-radius: 16px;
                text-align: center;
                margin-right: 140px;
                position: relative;

                &:last-child {
                    margin-right: 0;
                }

                .item-img {
                    width: 196px;
                    height: 196px;
                    margin-top: 54px;
                    margin-bottom: 22px;
                }

                .item-btn {
                    display: inline-block;
                    padding: 0 25px;
                    height: 40px;
                    line-height: 40px;
                    background: linear-gradient(90deg, #4E55E6 0%, #0300B5 100%);
                    border-radius: 4px;
                    margin: 0 auto;
                    color: #fff;
                    font-size: 14px;
                }

                .item-btn-disabled {
                    opacity: 0.4;
                    cursor: not-allowed;
                }

                .tips {
                    width: 100%;
                    position: absolute;
                    top: 25px;
                    left: 0;
                    text-align: center;
                    font-size: 14px;
                    color: red;
                }
            }
        }
    }

    .partner {
        width: 100%;
        /* background-color: #ffffff; */
        background-image: url("../../assets/home_img/8.png");
        background-size: 100% 100%;

        .partner_title {
            padding-top: 50px;
            text-align: left;
            font-size: 26px;
            font-weight: 500;
        }

        .partner_title2 {
            width: 263px;
            margin-top: 20px;
            text-align: left;
            line-height: 25px;
        }

        // 合作伙伴
        .partner_on {
            display: flex;
            width: 1440px;
            margin: 0 auto;
        }

        .partner_tw {
            width: 263px;
            margin-top: 20px;
        }

        .panter_list {
            margin-left: 36px;
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;
            justify-items: center;
            text-align: justify;
            padding: 55px 0;
            align-items: center;

            .partner_item {
                width: 180px;
                margin-top: 10px;
                margin-left: 10px;
                height: 72px;
                box-sizing: border-box;
                cursor: pointer;
            }
        }
    }

    .pointer {
        cursor: pointer;
    }
}
</style>